import jobBoard from "@/services/jobBoard";
import router from "@/router/index";
import { isObject,
  Role,
  getFilterQueryStringWithoutArray,
  getSkipLimitBypage,
  getPageNumberByIndexSkipLimitTotal,
  getLocalOrgDetail,
  CONSTANT as CONST,
} from "@/helpers/helper";

const state = {
  jobBoard: {},
  isLoading: false,
  selectedJobBoard: {},
  error: undefined,
  jobBoardPagination: {
    limit: 10,
    skip: 0,
    noMoreDataFromJobBoard: false
  },
  jobCount: 0,
  filterCount: 0,
  applicationStatus: null,
  selectedJobBenefitsAndAdvertDesc: [],
  lastFetchedJobAdvert: 0,
  jobAdvertisements: [],
  allCustomerOrganisation: [],
};

// create getters for all state variables
const getters = {
  isJobBoardFetching: () => state.isLoading,
  getJobBoard: (state, getters, rootState) => {
    return Object.keys(state.jobBoard).map((val) => state.jobBoard[val]);
  },
  getSelectedJobBoard: (state, getters, rootState) => state.selectedJobBoard,
  getSelectedJobBenefitsAndAdvertDesc: (state) => state.selectedJobBenefitsAndAdvertDesc,
  getApplicationStatus: (state, getters, rootState) => state.applicationStatus,
  getJobBoardPaginationSkip: (state, getters, rootState) =>
    state.jobBoardPagination.skip,
  getJobBoardPaginationLimit: (state, getters, rootState) =>
    state.jobBoardPagination.limit,
  getJobPaginationPageNumber: (state, getters, rootState) => {
    const {
      getJobBoardPaginationSkip: skip,
      getJobBoardPaginationLimit: limit,
      getJobIndex: index,
      getJobBoard
    } = getters;
    return getPageNumberByIndexSkipLimitTotal({ skip, limit, index, total: getJobBoard.length });
  },
  noMoreDataFromJobBoard: (state, getters, rootState) =>
    state.jobBoardPagination.noMoreDataFromJobBoard,
  getJobCount: (state, getters, rootState) => state.jobCount,
  getFilterCount: (state) => state.filterCount,
  jobAdvertisements: (state) => state.jobAdvertisements || [],
  allCustomerOrganisation: (state) => state.allCustomerOrganisation || [],
  getJobIndex: (state, getters, rootState) => {
    const { getJobDetailsById, getJobBoard, } = getters;
    const jobIndex = getJobBoard.findIndex(e => e.job_id === getJobDetailsById?.job_id);
    return jobIndex;
  }
};

// actions
const actions = {
  jobBoardDetailAction: async (
    { state, commit, rootState, dispatch, getters },
    { job_id, path }
  ) => {
    dispatch("showLoader");
    const { getCandidateId: candidate_id, getCustomerId: customer_id, getRoleScopes } = getters;
    return jobBoard
      .getJobDetail({
        customer_id,
        candidate_id,
        role: getRoleScopes.length ? getRoleScopes[0] : '',
        job_id,
        path
      })
      .then(res => {
        let { data } = res;
        const { getCandidateId } = getters;
        dispatch("fetchApplicationStatus", {
          job_id,
          candidate_uid: getCandidateId
        });
        if (Array.isArray(data)) {
          commit("SELECTED_JOBBOARD", { ...data[0] });
          commit("SELECTED_JOB_BENEFITS_AND_ADVERT", data[0])
        } else {
          commit("SELECTED_JOBBOARD", { ...data });
          commit("SELECTED_JOB_BENEFITS_AND_ADVERT", data)
        }
      })
      .catch(err => {
        console.error("error in get Job detail", err);
        return err;
      })
      .finally(() => {
        dispatch("hideLoader");
      });
  },
  jobBoardAction: async (
    { state, commit, rootState, dispatch, getters },
    payload = {}
  ) => {
    const {
      getCustomerId,
      getJobBoardPaginationSkip,
      getJobBoardPaginationLimit,
      getCandidateId,
      getRoleScopes,
      getRole,
      getIsChildUser,
      getIsPartnerHospital,
      getOrgID,
      candidateProfile,
      getRelationshipOrgDetail,
      isCandidateSupplierFromAccessToken,
      getJobCount,
    } = getters;
    commit("JOBBOARD");
    dispatch("showLoader");
    let skip = getJobBoardPaginationSkip;
    const limit = getJobBoardPaginationLimit;
    if (!payload.pagination) {
      const skipLimit = await getSkipLimitBypage(payload?.pageNumber, getJobBoardPaginationLimit)
      skip = skipLimit.skip;
      commit("RESET_JOB_BOARD_DATA");
      commit("RESET_SELECTED_JOB_BOARD");
      commit("SET_JOB_BOARD_PAGINATION", {
        skip,
        noMoreDataFromJobBoard: false
      });
    }
    let { page, query } = payload;
    let relationshipOrgDetail = [];
    if (getRelationshipOrgDetail?.length) {
      getRelationshipOrgDetail.forEach(item => {
        relationshipOrgDetail.push({
          org_id: item?.org_id,
          customer_id: item?.customer_id
        })
      });

    }
    const customer_id = getCustomerId;
    const { customerAdmin, systemCandidate, customerCandidate, customerRecruiter } = Role;
    let payloadVar = payload;
    if (!payload.pagination && !payload.isPageChanged) {
      if (customerAdmin === getRole && relationshipOrgDetail?.length) {
        dispatch("agencyJobCount", payload);
      } else if ([customerAdmin, customerRecruiter].includes(getRole)) {
        dispatch("facilityJobCount", payload);
      } else if ([systemCandidate, customerCandidate].includes(getRole)) {
        const { organisation_id } = candidateProfile;
        dispatch('candidateJobCount', payload);
        if (organisation_id) {
          query = `organisation_uid=${organisation_id}&${query}`;
        }
      } else {
        dispatch("jobCount", payload);
      }
    }
    commit("SET_JOB_LIST_FILTER", payload);
    commit("LAST_FETCHED_JOB_ADVERT", null);
    // if(!getJobCount){
    //   dispatch("jobCount");
    // }
    const { currentRoute: { fullPath, name, params } } = router
    if (fullPath.search("preferredType") != -1) {
      query = fullPath.search("favourite") != -1 ? "preferred_type=favourite" : "applied=true";
    }

    if (name === 'TFW Job Board' && params?.country_id) {
      const { country_id } = params;
      query = `is_super_customer=true&locations___country_id=${country_id}&${query}`
    }

    if (customerAdmin === getRole && relationshipOrgDetail?.length) {
      return jobBoard
        .getAllJobsForAgency({
          customer_id,
          skip,
          limit,
          page,
          query
        }, relationshipOrgDetail)
        .then(res => {
          try {
            let { data } = res;
            if (data.length < getJobBoardPaginationLimit) {
              commit("SET_JOB_BOARD_PAGINATION", {
                noMoreDataFromJobBoard: true
              });
            }
            commit("SET_JOB_BOARD_PAGINATION", {
              skip: skip + getJobBoardPaginationLimit
            });
            if (payloadVar && payloadVar.pagination) {
              commit("JOBBOARD_SUCCESS", { payload: data, pagination: payloadVar.pagination });
            } else {
              commit("JOBBOARD_SUCCESS", { payload: data });
              let queryJobId = parseInt(router.history.current.query.job_id) ? parseInt(router.history.current.query.job_id) : (data.length ? data[0].job_id : null);
              let selectedJobDetails = data.find(
                e => e.job_id === queryJobId
              );
              if (!selectedJobDetails) {
                selectedJobDetails = data[0]
              }
              if (selectedJobDetails) {
                dispatch("fetchJobBenefitsAdvertDescByJobId", {
                  job_id: selectedJobDetails?.job_id,
                  customer_id: selectedJobDetails?.customer_uid,
                });
              }
              commit("SELECTED_JOBBOARD", selectedJobDetails);
            }
          } catch (error) {
            console.error("error in jobBoard", error);
            commit("JOBBOARD_ERROR", error);
          }
        })
        .catch(err => {
          console.error("error in jobBoard", err);
          commit("JOBBOARD_ERROR", err);
          return err;
        })
        .finally(() => {
          dispatch("hideLoader");
        });
    } else {
      return jobBoard
        .getJobBoard({
          role: getRoleScopes.length ? getRoleScopes[0] : '',
          customer_id,
          skip,
          limit,
          page,
          query,
          candidate_id: getCandidateId,
          organisation_id: getIsChildUser || getIsPartnerHospital ? getOrgID : null,
          isCandidateSupplierFromAccessToken: isCandidateSupplierFromAccessToken
        })
        .then(res => {
          try {
            let { data } = res;
            if (data.length < getJobBoardPaginationLimit) {
              commit("SET_JOB_BOARD_PAGINATION", {
                noMoreDataFromJobBoard: true
              });
            }
            commit("SET_JOB_BOARD_PAGINATION", {
              skip: skip + getJobBoardPaginationLimit
            });
            if (payloadVar && payloadVar.pagination) {
              commit("JOBBOARD_SUCCESS", { payload: data, pagination: payloadVar.pagination });
            } else {
              commit("JOBBOARD_SUCCESS", { payload: data });
              let queryJobId = parseInt(router.history.current.query.job_id) ? parseInt(router.history.current.query.job_id) : (data.length ? data[0].job_id : null);
              let selectedJobDetails = data.find(
                e => e.job_id === queryJobId
              );
              if (!selectedJobDetails) {
                selectedJobDetails = data[0]
              }
              if (selectedJobDetails) {
                dispatch("fetchJobBenefitsAdvertDescByJobId", {
                  job_id: selectedJobDetails?.job_id,
                  customer_id: selectedJobDetails?.customer_uid,
                });
              }
              commit("SELECTED_JOBBOARD", selectedJobDetails);
            }
          } catch (error) {
            console.error("error in jobBoard", error);
            commit("JOBBOARD_ERROR", error);
          }
        })
        .catch(err => {
          console.error("error in jobBoard", err);
          commit("JOBBOARD_ERROR", err);
          return err;
        })
        .finally(() => {
          dispatch("hideLoader");
        });
    }
  },
  modifyPublishJob: async ({ commit, dispatch, getters }, payload) => {
    const { getCustomerId, } = getters;
    const { organisation_uid, ...rest } = payload
    return jobBoard
      .updateJob(rest, organisation_uid, getCustomerId)
      .then(async res => {
        const { data } = res;
        const job_id = rest.job_id;
        commit("SELECTED_JOBBOARD", res.data);
        commit('UPDATE_JOB_BOARD_LIST_BY_JOB_ID', res.data)
        let pending_job = data?.status_id == 28 ? true : false;
        dispatch("showToast", {
          class: 'bg-success text-white', message:
            rest.published ? pending_job ? 'Job sent for Approval...' : 'Job Published successfully' : 'Updated successfully'
        });
        let result = Object.assign({}, { ...state.jobBoard, [`job_${[job_id]}`]: data })
      })
      .catch(err => {
        console.log(err, 'err')
        let msg = "Error while saving";
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      });
  },
  fetchApplicationStatus: ({ commit, dispatch, getters }, payload) => {
    const { getRoleScopes } = getters;
    let checkOnly = ["system_candidate", "customer_candidate"];
    if (payload.candidate_uid && checkOnly.includes(getRoleScopes[0])) {
      return jobBoard
        .fetchApplicationStatus(payload)
        .then(res => {
          let { data } = res;
          commit('SET_APPLICATION_STATUS', data[0])
        })
        .catch(err => {
          console.error("error in fetchApplicationStatus", err);
          return err;
        })
    }
  },

  selectedJobBoard: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    try {
      const { getCandidateId, getJobBoard } = getters;
      if (getJobBoard && getJobBoard.length) {
        const selectedJobBoard = getJobBoard.filter(
          e => e.job_id === payload
        )[0];
        if (selectedJobBoard && selectedJobBoard.length) {
          dispatch("fetchApplicationStatus", {
            job_id: selectedJobBoard && selectedJobBoard.job_id,
            candidate_uid: getCandidateId
          })
        }
        if (selectedJobBoard) {
          dispatch("fetchJobBenefitsAdvertDescByJobId", {
            job_id: selectedJobBoard?.job_id,
            customer_id: selectedJobBoard?.customer_uid,
          });
        }
        commit("SELECTED_JOBBOARD", selectedJobBoard);
      }
    } catch (e) {
      console.error("error occured in action selectedJobBoard ", e)
    }
  },
  applyJob: async (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { job_id, customer_uid } = payload;
    const { getCandidateId } = getters;
    return jobBoard
      .applyJob(getCandidateId, job_id, { comments: "" })
      .then(async res => {
        let { data } = res;
        commit("SET_APPLICATION_STATUS", data);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Application successful!"
        });
        commit("REMOVE_MATCHED_ARRAY", job_id)
        await dispatch("updateCandidateJobListByJobID", { job_id, customer_uid });
        return data;
      })
      .catch(err => {
        console.error("error in applying", err);
        return err;
      });
  },

  withdrawJob: async (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { getCandidateId } = getters;
    const { job_id } = payload
    return jobBoard
      .withdrawJob(getCandidateId, job_id)
      .then(async res => {
        let { data } = res;
        commit("SET_APPLICATION_STATUS", "");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Successfully withdrawn from this job application!"
        });
        await dispatch("updateCandidateJobListByJobID", { job_id });
        return data;
      })
      .catch(err => {
        console.error("error in applying", err);
        return err;
      });
  },
  resetApplicationStatus({ commit }) {
    commit("SET_APPLICATION_STATUS", "");
  },
  agencyJobCount: ({ commit, getters }, { query, isTotal }) => {
    const { getRelationshipOrgDetail, getCustomerId: customer_id } = getters;
    query = query || "";
    let relationshipOrgDetail = [];
    {
      getRelationshipOrgDetail.forEach(item => {
        relationshipOrgDetail.push({
          org_id: item?.org_id,
          customer_id: item?.customer_id
        })
      });
    }
    return jobBoard.getAllJobsCountForAgency(query, customer_id, relationshipOrgDetail)
      .then(res => {
        let { data } = res;
        // if (!query) {
        //   commit("JOB_COUNT", data);
        // }
        // commit("FILTER_COUNT", query ? data : 0);
        if (isTotal || !query) {
          commit("JOB_COUNT", data)
        }
        if (!isTotal)
          commit("FILTER_COUNT", data);
        return res;
      })
      .catch(err => {
        console.log("error in getting job count", err);
        return err;
      });
  },

  facilityJobCount: ({ commit, getters }, { query, isTotal }) => {
    const { getCustomerId, getIsChildUser, getIsPartnerHospital, getOrgID: org_id, isCandidateSupplierFromAccessToken } = getters;
    query = query || "";
    return jobBoard
      .getJobsCountFacility(query, getCustomerId, getIsChildUser || getIsPartnerHospital ? org_id : null, isCandidateSupplierFromAccessToken)
      .then(res => {
        let { data } = res;
        // if (!query) {
        //   commit("JOB_COUNT", data);
        // }
        // commit("FILTER_COUNT",  data);
        if (isTotal || !query) {
          commit("JOB_COUNT", data)
        }
        if (!isTotal)
          commit("FILTER_COUNT", data);
        return res;
      })
      .catch(err => {
        console.log("error in getting job count", err);
        return err;
      });
  },

  jobCount: ({ commit, getters, dispatch }, { query, isTotal }) => {
    const { getJobCount, getCandidateId, getCustomerId, getRoleScopes } = getters
    query = query || "";
    let role = getRoleScopes.length ? getRoleScopes[0] : ''
    const { currentRoute: { fullPath, name, params } } = router
    if (name === 'TFW Job Board' && params?.country_id) {
      const { country_id } = params;
      query = `is_super_customer=true&locations___country_id=${country_id}&${query}`
    }
    return jobBoard.getJobsCount({
      query, candidate_id: getCandidateId, customer_id: getCustomerId, role
    })
      .then(res => {
        let { data } = res;
        if (!query || name === 'TFW Job Board') {
          commit("JOB_COUNT", data);
        }
        if (!isTotal)
          commit("FILTER_COUNT", data);
        if (!getJobCount) {
          query = ""
          if (name === 'TFW Job Board' && params?.country_id) {
            const { country_id } = params;
            query = `is_super_customer=true&locations___country_id=${country_id}&${query}`
          }
          return jobBoard.getJobsCount({ query, role }).then(res => {
            commit("JOB_COUNT", res.data);
          })
        }
        return res;
      })
      .catch(err => {
        console.error("error in getting job count", err);
        return err;
      });
  },
  fetchJobBenefitsAdvertDescByJobId: ({ commit, dispatch, getters }, payload) => {
    const { getRoleScopes } = getters
    if (state.lastFetchedJobAdvert == payload.job_id) return
    commit("LAST_FETCHED_JOB_ADVERT", payload.job_id)
    commit("SELECTED_JOB_BENEFITS_AND_ADVERT", []);
    dispatch("showLoader");
    if (getRoleScopes[0] && (getRoleScopes[0] == Role.customerAdmin
      || getRoleScopes[0] == Role.systemAdmin
      || getRoleScopes[0] == Role.systemRecruiter
      || getRoleScopes[0] == Role.customerRecruiter
      || getRoleScopes[0] == Role.customerUser)) {
      return jobBoard
        .fetchJobBenefitsAdvertDescByJobId(payload).then(res => {
          let { data } = res;
          commit("SELECTED_JOB_BENEFITS_AND_ADVERT", { ...data, ...data.advert_description });
          return res
        })
        .catch(err => {
          console.error("error in fetching candidate page job details", err);
          return err;
        })
        .finally(res => dispatch("hideLoader"))
    } else {
      return jobBoard
        .fetchPublicAdvertDescForJobId(payload)
        .then(async res => {
          let { data } = res;
          commit("SELECTED_JOB_BENEFITS_AND_ADVERT", data[0]);
          return res
        })
        .catch(err => {
          console.error("error in fetching advert description", err);
          return err;
        })
        .finally(res => dispatch("hideLoader"))
    }
  },
  addPreferredJob: ({ getters, dispatch }, payload) => {
    const { job_id, preferred_type } = payload;
    dispatch('showToast', { message: `Adding into ${preferred_type} jobs...` });
    const { getCandidateId: candidate_id, getCustomerId: customer_id } = getters;
    return jobBoard
      .addPreferredJob({ candidate_id, customer_id, payload })
      .then(async res => {
        let { data } = res;
        dispatch("clearAllToast");
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: `Job Added into ${preferred_type} list successfully`,
        });
        await dispatch("updateCandidateJobListByJobID", { job_id });
        return data;
      })
      .catch(err => {
        dispatch("clearAllToast");
        let msg = `Error while Adding into ${preferred_type} job list`;
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        console.error(msg, err);
        return err;
      });
  },
  deletePreferredJob: ({ getters, dispatch }, payload) => {
    const { preferred_id, job_id, preferred_type } = payload;
    dispatch('showToast', { message: `Deleting from ${preferred_type} jobs...` });
    const { getCandidateId: candidate_id, getCustomerId: customer_id } = getters;
    return jobBoard
      .deletePreferredJob({ candidate_id, customer_id, preferred_id })
      .then(async res => {
        let { data } = res;
        dispatch("clearAllToast");
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: `Removed Job from ${preferred_type} list successfully`,
        });
        await dispatch("updateCandidateJobListByJobID", { job_id });
        return data;
      })
      .catch(err => {
        dispatch("clearAllToast");
        let msg = `Error while ${preferred_type} job`
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        console.error(msg, err);
        return err;
      });
  },
  updateCandidateJobListByJobID: ({ getters, dispatch, commit }, payload = {}) => {
    const { job_id, customer_uid } = payload
    const { getCandidateId: candidate_id, getCustomerId } = getters;
    const customer_id = customer_uid || getCustomerId;
    return jobBoard
      .getJobDetail({ job_id, candidate_id, customer_id })
      .then(res => {
        let { data } = res;
        let jobDetails;
        if (Array.isArray(data)) {
          jobDetails = data[0];
        } else {
          jobDetails = data;
        }
        commit('UPDATE_JOB_BOARD_LIST_BY_JOB_ID', jobDetails)
        commit("SELECTED_JOBBOARD", jobDetails);
        commit('UPDATE_MATCHED_ARRAY', jobDetails);
        commit("UPDATE_FAV_ARRAY", jobDetails);
        return data;
      })
      .catch(err => {
        let msg = "Error while Fetch job by ID";
        console.error(msg, err);
        return err;
      });
  },
  candidateJobCount: ({ commit, getters }, { query, isTotal }) => {
    let { getCustomerId: customer_id, getCandidateId: candidate_id, candidateProfile, getJobCount } = getters;
    let initQuery = query || "";
    let serviceFunc = "getJobsCountCandidate"
    const { currentRoute: { fullPath } } = router
    if (fullPath.search("preferredType") != -1 && getJobCount) {
      initQuery = fullPath.search("favourite") != -1 ? "preferred_type=favourite" : "preferred_type=applied";
      serviceFunc = "getPreferredJobCount"
      if (fullPath.search("applied") != -1) {
        serviceFunc = "candidateAppliedJobsCount"
      }
    }
    query = initQuery;
    const { organisation_id } = candidateProfile
    if (getLocalOrgDetail()?.customer_type_id ===  CONST.CUSTOMER_TYPES.educational_institute) {
      customer_id = CONST.CUSTOMERS.super_customer
      query += `&organisation_uid=${CONST.ORGANISATIONS.super_customer}`;
    }
    else if (organisation_id) {
      query += `&organisation_uid=${organisation_id}`;
    }
    return jobBoard[serviceFunc]({ query, customer_id, candidate_id })
      .then(res => {
        let { data } = res;
        // if (!initQuery) {
        //   commit("JOB_COUNT", data)
        // }
        // commit("FILTER_COUNT", initQuery ? data : 0);

        if (isTotal || !initQuery) {
          commit("JOB_COUNT", data)
        }
        if (!isTotal)
          commit("FILTER_COUNT", data);
        return res;
      })
      .catch(err => {
        console.log("error in getting job count", err);
        return err;
      });
  },

  fetchJobAdvertisement({ dispatch, commit }, payload) {
    commit("RESET_JOB_ADVERTISEMENTS", []);
    const queryString = getFilterQueryStringWithoutArray(payload);
    return jobBoard.getJobAdvertisements({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_JOB_ADVERTISEMENTS", data);
        return data;
      })
      .catch(err => {
        const message = "Error while Fetching Job Advertisements";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
  },
  uploadAdvert({ dispatch, commit }, payload) {
    const { link, file, fileName, customer_id, organisation_id } = payload;
    const queryPayload = { customer_id, organisation_id, link };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    const document = new FormData();
    document.append("file", file, fileName);
    dispatch("showToast", { message: 'Uploading...' })
    dispatch("showLoader")
    return jobBoard.uploadAdvert({ queryString, document })
      .then(res => {
        const { data } = res;
        dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded Successfully!' })
        return data;
      })
      .catch(err => {
        const message = "Error While Adding Advert";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
      .finally(res => {
        dispatch("hideLoader");
      })
  },
  updateAdvert({ dispatch }, payload) {
    const { link, file, fileName, job_advertisement_id, customer_id, organisation_id, is_active } = payload;
    let queryPayload = {};
    if (customer_id)
      queryPayload = { ...queryPayload, customer_id }
    if (organisation_id)
      queryPayload = { ...queryPayload, organisation_id }
    if (link)
      queryPayload = { ...queryPayload, link }
    if (is_active != null)
      queryPayload = { ...queryPayload, is_active }
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    dispatch("showToast", { message: 'Updating...' })
    dispatch("showLoader")
    let document = new FormData();
    if (fileName) {
      document.append("file_name", file, fileName);
    }
    return jobBoard.updateAdvert({ job_advertisement_id, queryString, document, fileName })
      .then(res => {
        const { data } = res;
        dispatch("showToast", { class: 'bg-success text-white', message: 'Updated Successfully!' })
        return data;
      })
      .catch(err => {
        const message = "Error While Updating Advert";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
      .finally(res => {
        dispatch("hideLoader");
      })
  },
  deleteJobAdvert({ dispatch }, payload) {
    return jobBoard.deleteJobAdvert(payload)
      .then(res => {
        const { data } = res;
        dispatch("showToast", { class: 'bg-success text-white', message: 'Deleted Successfully!' })
        return data;
      })
      .catch(err => {
        const message = "Error While Delete Advert";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
  },
  fetchAllCustomerOrganisation({ commit, getters, dispatch }, check_child_organisations_has_acronym) {
    const { getCustomerId: customer_id, getIsParentUserFromAccessToken,
      getIsChildUserFromAccessToken, getOrgIDFromAccessToken: org_id } = getters;
    let query = "";
    if (getIsChildUserFromAccessToken) { query += `organisation_id=${org_id}` }
    if (check_child_organisations_has_acronym && getIsParentUserFromAccessToken) {
      query += `check_child_organisations_has_acronym=true&parent_org_id=${org_id}`
    }
    return jobBoard.getCustomerOrganisation({ customer_id, query })
      .then(res => {
        const { data } = res;
        commit("SET_ALL_CUSTOMER_ORGANISATION", data);
        return data;
      })
      .catch(err => {
        const message = "Error While Customer Organisation";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
  }

};

// mutations
const mutations = {
  ["JOBBOARD_ERROR"](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  ["SET_JOB_BOARD_PAGINATION"](state, data) {
    state.jobBoardPagination = { ...state.jobBoardPagination, ...data };
  },
  ["JOBBOARD_SUCCESS"](state, { payload, pagination }) {
    let result = {}
    if (isObject(payload)) {
      result = payload;
    } else {
      payload.forEach(item => {
        result[`job_${item.job_id}`] = item;
      });
    }
    if (pagination) {
      state.jobBoard = { ...state.jobBoard, ...result };
    } else {
      state.jobBoard = { ...result };
    }
    state.isLoading = false;
  },
  ['RESET_JOB_BOARD_DATA'](state) {
    state.jobBoard = [];
  },
  ["JOBBOARD"](state) {
    state.isLoading = true;
  },
  ["SELECTED_JOBBOARD"](state, payload) {
    state.selectedJobBoard = payload;
  },
  ["RESET_SELECTED_JOB_BOARD"](state) {
    state.selectedJobBoard = {};
  },
  ["SELECTED_JOB_BENEFITS_AND_ADVERT"](state, payload) {
    state.selectedJobBenefitsAndAdvertDesc = payload
  },
  ["LAST_FETCHED_JOB_ADVERT"](state, payload) {
    state.lastFetchedJobAdvert = payload;
  },
  ["SET_APPLICATION_STATUS"](state, payload) {
    state.applicationStatus = payload
  },
  ["JOB_COUNT"](state, payload) {
    state.jobCount = payload;
  },
  ["FILTER_COUNT"](state, payload) {
    state.filterCount = payload;
  },
  ['SET_JOB_LIST_FILTER'](state, payload) {
    state.jobListFilter = payload
  },
  ['UPDATE_JOB_BOARD_LIST_BY_JOB_ID'](state, payload) {
    let job_id = `job_${[payload.job_id]}`
    state.jobBoard[`${job_id}`] = payload
    state.selectedJobBenefitsAndAdvertDesc = payload
  },
  ['ADD_JOB_BOARD_LIST_BY_JOB_ID'](state, payload) {
    state.jobBoard = {
      [`job_${[payload.job_id]}`]: payload,
      ...state.jobBoard
    }
    state.jobCount += 1;
    if (state.filterCount) state.filterCount += 1;
  },
  ['UPDATE_JOB_BOARD_BY_REMOVING_JOB'](state, job_id) {
    job_id = `job_${[job_id]}`
    let job_board = state.jobBoard;
    state.jobBoard = [];
    delete job_board[job_id];
    state.jobBoard = job_board;
    state.selectedJobBoard = Object.keys(state.jobBoard).length ? state.jobBoard[Object.keys(state.jobBoard)[0]] : [];
    state.jobCount -= 1;
    if (state.filterCount) state.filterCount -= 1;
  },
  ['SET_JOB_ADVERTISEMENTS'](state, payload) {
    state.jobAdvertisements = payload;
  },
  ['RESET_JOB_ADVERTISEMENTS'](state) {
    state.jobAdvertisements = [];
  },
  ['SET_ALL_CUSTOMER_ORGANISATION'](state, data) {
    state.allCustomerOrganisation = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
